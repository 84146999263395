
import Request from "@/module/request/request"
import Utils from "@/module/utils/utils"
import Highcharts from "highcharts"
import Message from "@/module/interactive/message"
import Component, { ComponentMethods } from "../module/component/component"

import elemIcon from "@/components/elem-icon.vue"
// import Cache from '@/module/cache/cache'

class HomeView extends ComponentMethods implements ComponentEntity {
    public title: string = "首页"

    public icon: string = "home"

    private messages: obj[] = []

    private visitsChart: Highcharts.Chart

    public statVehicles: obj = {}

    // 收支统计
    private statIncExp: obj = {}

    // 借款统计
    private statLoan: obj = {}

    private info = {}

    components = {
        elemIcon,
    }

    activated() {
        this.getVehiclesStatistics()
        this.getStatIncExp()
    }

    /**
     * 获取车辆统计数据
     */
    private getVehiclesStatistics() {
        Request.get<obj>("WEB://home/statistics/StatVehicles").then(res => {
            this.statVehicles = res
            // 绘制车辆新增图表
            this.setVehiclesChart(res.add12MonthData, res.add12LastMonthData)
        })
    }

    /**
     * 获取收支、借款统计数据
     */
    private getStatIncExp() {
        Request.get<obj>("WEB://home/statistics/StatIncExp").then(res => {
            this.statIncExp = res.incExp
            this.statLoan = res.loan
        })
    }

    public initMessageSocket() {
        // 判断当前浏览器是否支持 WebSocket
        if (!("WebSocket" in window)) {
            return
        }

        const socket = new WebSocket(`${window.location.protocol.indexOf("https") > -1 ? "wss" : "ws"}://${window.location.hostname}/WebSocket/Chat`)

        const audio = new Audio("static/audio/message.mp3")

        //连接发生错误的回调方法
        socket.onerror = function (evt) {
            console.error(evt)
        }

        //接收到消息的回调方法
        socket.onmessage = event => {
            this.messages.push(JSON.parse(event.data))

            // if (Cache.get<obj>("system", {}).audio) {
            audio.play()
            // }
        }

        // 监听窗口关闭事件
        window.onbeforeunload = function () {
            socket.close()
        }
    }

    public getAccessChartData() {
        Request.get("ADMIN://Access/GetAllChartData").then(res => {
            this.accessChartData = res

            if (this.visitsChart) {
                // 销毁访问数据图表
                this.visitsChart.destroy()
            }

            // 绘制
            this.setVisitsChart()
        })
    }

    /**
     * 获取未读消息
     */
    getUnreadMessage() {
        Request.get<obj[]>("ADMIN://Message/FindAllUnread").then(res => {
            this.messages = this.messages.concat(res)
        })
    }

    /**
     * 获取消息图标
     */
    getMessageIcon(type: string, dark: boolean = false): string {
        var name: string

        switch (type) {
            case "ERROR":
                name = "error"
                break
            case "SUCCESS":
                name = "success"
                break
            default:
                name = "info"
        }

        if (dark) {
            name += "_dark"
        }

        return name
    }

    /**
     * 获取订单图表数据
     */
    getOrderChartData() {
        Request.get<obj[]>("ADMIN://OrderChart/GetAllChartData").then(res => {
            if (res.length <= 0) return

            this.ordersChartData = res

            if (this.ordersChart) {
                // 销毁订单数据图表
                this.ordersChart.destroy()
            }

            // 绘制
            this.setOrdersChart()
        })
    }

    /**
     * 监听主题变化事件
     */
    onChangeTheme(theme) {
        this.setDarkTheme(theme === "dark")
    }

    getCompare(num: number): string {
        return num >= 0 ? "⬆" + num : "⬇" + Math.abs(num)
    }

    setDarkTheme(dark: boolean) {
        Highcharts.theme = {
            colors: ["#058DC7", "#50B432", "#ED561B", "#DDDF00", "#24CBE5", "#64E572", "#FF9655", "#FFF263", "#6AF9C4"],
            chart: {
                backgroundColor: "transparent",
            },
            xAxis: {
                lineColor: dark ? "#555" : "#333",
                labels: {
                    style: {
                        color: dark ? "#fff" : "#333",
                    },
                },
            },
            yAxis: {
                gridLineColor: dark ? "#555" : "#e3e3e3",
                title: {
                    style: { color: dark ? "#fff" : "#333" },
                },
            },
            legend: {
                itemStyle: {
                    color: dark ? "#fff" : "#333",
                },
                itemHoverStyle: {
                    color: dark ? "#fff" : "#333",
                },
            },
        }

        // 使主题配置生效
        Highcharts.setOptions(Highcharts.theme)

        if (this.visitsChart) {
            // 销毁访问数据图表
            this.visitsChart.destroy()
            // 重新绘制
            this.setVisitsChart()
        }

        if (this.ordersChart) {
            // 销毁订单数据图表
            this.ordersChart.destroy()
            // 重新绘制
            this.setOrdersChart()
        }
    }

    setOrdersChart() {
        this.ordersChart = Highcharts.chart({
            chart: {
                renderTo: "OrdersChart",
                type: "spline",
            },
            title: {
                text: null,
            },
            credits: {
                enabled: false,
            },
            xAxis: {
                categories: (function () {
                    const date = new Date()
                    const dates = []

                    date.setDate(date.getDate() - 30)

                    for (let i = 0; i < 30; i++) {
                        date.setDate(date.getDate() + 1)

                        let day = date.getDate()

                        dates.push(`${date.getMonth() + 1}.${day < 10 ? "0" + day : day}`)
                    }

                    return dates
                })(),
            },
            yAxis: {
                min: 0,
                gridLineDashStyle: "Dash",
                title: {
                    text: "订单金额（元）",
                },
            },
            tooltip: {
                shared: true,
            },
            plotOptions: {
                spline: {
                    marker: {
                        radius: 0,
                        lineWidth: 0,
                    },
                },
            },
            series: Utils.each(this.ordersChartData, v => {
                v.type = "spline"
            }),
        })
    }

    /**
     * 设置车辆新增图表
     */
    setVehiclesChart(data: obj, formerYears: obj) {
        this.vehiclesChart = Highcharts.chart({
            chart: {
                renderTo: "VehiclesChart",
                type: "area",
            },
            title: {
                text: null,
            },
            credits: {
                enabled: false,
            },
            xAxis: {
                categories: (function () {
                    // 12 个月
                    const date = new Date()
                    const dates = []

                    date.setMonth(date.getMonth() - 12)

                    for (let i = 0; i < 12; i++) {
                        date.setMonth(date.getMonth() + 1)

                        dates.push(`${date.getFullYear()}.${date.getMonth() + 1}`)
                    }

                    return dates
                })(),
                crosshair: true,
            },
            yAxis: {
                min: 0,
                gridLineDashStyle: "Dash",
                title: {
                    text: "新增车辆 (辆)",
                },
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px;color:#333">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b style="color:#333">{point.y} 辆</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
            },
            plotOptions: {
                areaspline: {
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1,
                        },
                        stops: [
                            [0, "rgba(20,116,223,0.42)"],
                            [1, "rgba(20,116,223,0.00)"],
                        ],
                    },
                    marker: {
                        radius: 0,
                        lineWidth: 0,
                    },
                },
                column: {
                    borderWidth: 0,
                },
            },
            series: [
                {
                    name: "新增车辆",
                    data: Object.keys(data).map(k => data[k]),
                    type: "areaspline",
                },
                {
                    name: "往年同期新增车辆",
                    data: Object.keys(formerYears).map(k => formerYears[k]),
                    type: "areaspline",
                },
            ],
        })
    }

    /**
     * 跳转到消息页面
     */
    onJumpMessagePage(i: string): void {
        this.jump("/message", { i })
    }

    /**
     * 监听删除消息事件
     */
    onDeleteMessage(i: string): void {
        Message.info("确认删除该消息？", true)
            .onConfirm(() => {
                Request.delete("ADMIN://Message/Delete", { i }).then(() => {
                    Message.success("删除成功")
                    // 更新数据
                    this.getUnreadMessage()
                })
            })
            .build()
    }
}

export default Component.build(new HomeView())
